<template>
  <router-view/>
</template>

<style lang="sass">

html, body
  overflow-x: scroll
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none
  scrollbar-width: none
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar
    display: none
</style>
